import React, { useEffect } from 'react';
import { BrowserRouter as Router, useLocation, Route, Routes, Link as RouterLink } from 'react-router-dom';
import styled, { createGlobalStyle, keyframes } from 'styled-components';
import { Link } from 'react-scroll';
import PrivacyPolicy from './PrivacyPolicy'; 

import appStoreBadge from './assets/app-store-badge.png';
import backgroundTop from './assets/background-top.png';
import backgroundBottom from './assets/background-bottom.png';
import backgroundCenter from './assets/background-middle.png';
import heroImage from './assets/shark.png';
import lucyImage from './assets/clownfish.png';
import jacobImage from './assets/octopus.png';

import gameplayImage from './assets/tap.png';
import charactersImage from './assets/turtle.png';
import devicesImage from './assets/devices.png';
import leaderboardImage from './assets/trophy.png';
import coinsImage from './assets/stars.png';
import './assets/fonts/editundo.ttf';



const GlobalStyle = createGlobalStyle`
  @font-face {
    font-family: 'Edit Undo BRK';
    src: url('/assets/fonts/editundo.ttf') format('truetype');
    font-weight: normal;
    font-style: normal;
  }

  @import url('https://fonts.googleapis.com/css2?family=Fredoka:wght@300..700&display=swap');

  body {
    margin: 0;
    padding: 0;
    font-family: 'Fredoka', sans-serif;
    font-size: 18px;
    color: white;
    background: 
      url(${backgroundTop}) top center / auto 100vh repeat-x,
      url(${backgroundBottom}) bottom center / auto 100vh repeat-x,
      url(${backgroundCenter}) center center / auto 100vh repeat;
    image-rendering: pixelated;
    scroll-behavior: smooth;
  }
  
  * {
    box-sizing: border-box;
  }

  h1, h2, h3, h4, h5, h6 {
    font-family: 'Edit Undo BRK', sans-serif;
  }

  h1 {
    font-size: 4.5rem;
  }

  h2 {
    font-size: 3.5rem;
  }

  h3 {
    font-size: 3rem;
  }

  h4 {
    font-size: 2.5rem;
  }

  h5 {
    font-size: 2rem;
  }

  h6 {
    font-size: 1.5rem;
  }

  p, li {
    font-size: 1.5rem;
  }

  @media (max-width: 768px) {
    body {
      font-size: 16px;
    }
    h1 {
      font-size: 3.5rem;
    }
    h2 {
      font-size: 2.5rem;
    }
    h3 {
      font-size: 2rem;
    }
    h4 {
      font-size: 1.75rem;
    }
    h5 {
      font-size: 1.5rem;
    }
    h6 {
      font-size: 1.25rem;
    }
    p, li {
      font-size: 1.25rem;
    }
  }

  @media (max-width: 480px) {
    body {
      font-size: 14px;
    }
    h1 {
      font-size: 3rem;
    }
    h2 {
      font-size: 2rem;
    }
    h3 {
      font-size: 1.75rem;
    }
    h4 {
      font-size: 1.5rem;
    }
    h5 {
      font-size: 1.25rem;
    }
    h6 {
      font-size: 1rem;
    }
    p, li {
      font-size: 1rem;
    }
  }
`;

const AppContainer = styled.div`
  text-align: center;
  display: flex;
  flex-direction: column;
  align-items: center;
`;

const HeaderContainer = styled.header`
  width: 100%;
  position: fixed;
  top: 0;
  background-color: #057adeb5;
  padding: 20px 0;
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1000;

  @media (max-width: 768px) {
    padding: 10px 0;
  }
`;

const Nav = styled.nav`
  display: flex;
  justify-content: space-around;
  flex-wrap: wrap;
  width: 80%;
  max-width: 1200px;

  @media (max-width: 768px) {
    justify-content: center;
  }
`;

const NavLink = styled(Link)`
  color: #ffffff;
  text-decoration: none;
  margin: 0 15px;
  cursor: pointer;
  white-space: nowrap; /* Ensure text doesn't wrap */

  &:hover {
    text-decoration: underline;
  }

  @media (max-width: 768px) {
    margin: 10px 5px; /* Adjust margin for mobile */
    font-size: 14px; /* Adjust font size for mobile */
  }
`;

const RouterNavLink = styled(RouterLink)`
color: #ffffff;
text-decoration: none;
margin: 0 15px;
cursor: pointer;
white-space: nowrap; /* Ensure text doesn't wrap */

&:hover {
  text-decoration: underline;
}

@media (max-width: 768px) {
  margin: 10px 5px; /* Adjust margin for mobile */
  font-size: 14px; /* Adjust font size for mobile */
}
`;

const fadeInUp = keyframes`
  from {
    opacity: 0;
    transform: translateY(20px);
  }
  to {
    opacity: 1;
    transform: translateY(0);
  }
`;

const Section = styled.section`
  width: 100%;
  max-width: 1000px;
  padding: 80px 20px;
  text-align: center;
  position: relative;
  z-index: 1;
  background: transparent; /* Transparent background */
  animation: ${fadeInUp} 0.8s ease-in-out;
`;

const HeroSection = styled(Section)`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  height: 100vh;
`;

const FeaturesSection = styled(Section)`
  background-color: transparent; /* Transparent background */
`;

const GameplaySection = styled(Section)`
  background-color: transparent; /* Transparent background */
`;

const FAQSection = styled(Section)`
  background-color: transparent; /* Transparent background */
`;

const FooterSection = styled(Section)`
  background-color: transparent; /* Transparent background */
`;

const Title = styled.h2`
  margin-bottom: 20px;
`;

const HeroImage = styled.img`
  width: 200px;
  height: auto;
  margin-bottom: 20px;
`;

const HeroTitle = styled.h1`
  margin-bottom: 20px;
`;

const HeroSubtitle = styled.h2`
  margin-bottom: 40px;
`;

const FeatureImage = styled.img`
  height: 50px;
  margin-bottom: 10px;
`;

const List = styled.ul`
  list-style: none;
  padding: 0;
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  gap: 60px; /* Add spacing between each feature */
  
  @media (max-width: 768px) {
    flex-direction: column;
    gap: 30px;
  }
`;

const ListItem = styled.li`
  display: flex;
  flex-direction: column;
  align-items: center;
  font-size: 1.5rem;
  text-align: center;
  flex: 1 1 25%;
  max-width: 25%;

  @media (max-width: 768px) {
    flex: 1 1 100%;
    max-width: 100%;
  }

  strong {
    display: block;
    margin-bottom: 10px;
  }

  span {
    display: block;
  }
`;

const SocialLinks = styled.div`
  display: flex;
  justify-content: center;
  gap: 20px;
  margin-top: 20px;
  cursor: pointer;
`;

const SocialLink = styled.a`
  display: flex;
  flex-direction: column;
  align-items: center;
  text-decoration: none;
  color: white;
  cursor: pointer;

  &:hover {
    text-decoration: underline;
  }

  span {
    margin-top: 5px;
  }
`;

const SocialImage = styled.img`
  width: 100px;
  height: 100px;
  cursor: pointer;
`;

const Text = styled.p``;

const AppStoreButton = styled.a`
  display: inline-block;
  margin-top: 20px;
  animation: bounce 2s infinite;
  cursor: pointer;
`;

const AppStoreImage = styled.img`
  height: 60px;
  cursor: pointer;
`;

const ScrollToTop = () => {
  const { pathname } = useLocation();

  useEffect(() => {
    window.scrollTo(0, 0);
  }, [pathname]);

  return null;
};

const Home = () => (
  <HeroSection id="home">
    <HeroImage src={heroImage} alt="Splashy Sprint" />
    <HeroTitle>Splashy Sprint</HeroTitle>
    <HeroSubtitle>Swim fast, tap faster – conquer the ocean depths!</HeroSubtitle>
    <AppStoreButton href="https://apps.apple.com" aria-label="Download on the App Store">
      <AppStoreImage src={appStoreBadge} alt="Download on the App Store" />
    </AppStoreButton>
  </HeroSection>
);

const Gameplay = () => (
  <GameplaySection id="gameplay" aria-labelledby="gameplay-title">
    <Title id="gameplay-title">Gameplay</Title>
    <Text>
      Get ready to tap like crazy in Splashy Sprint! Guide your underwater character through endless obstacles, collecting coins and avoiding a watery demise. The controls are simple, but don't be fooled – it's maddeningly tough. Available on Apple Watch and iPhone, this game will have you shouting "just one more try!" as you chase the top spot on the leaderboard. Dive in and prove you’re the true king of the ocean!
    </Text>
  </GameplaySection>
);

const Features = () => (
  <FeaturesSection id="features" aria-labelledby="features-title">
    <Title id="features-title">Features</Title>
    <List>
      <ListItem>
        <FeatureImage src={gameplayImage} alt="Challenging Tap Gameplay" />
        <strong>Challenging Tap Gameplay:</strong>
        <span>Simple to start, but can you master it? Tap to dive and dodge!</span>
      </ListItem>
      <ListItem>
        <FeatureImage src={charactersImage} alt="Unlockable Characters" />
        <strong>Unlockable Characters:</strong>
        <span>Meet and play with a host of underwater friends.</span>
      </ListItem>
      <ListItem>
        <FeatureImage src={devicesImage} alt="Apple Watch & iPhone" />
        <strong>Apple Watch & iPhone:</strong>
        <span>Enjoy seamless gameplay on both devices, wherever you are.</span>
      </ListItem>
      <ListItem>
        <FeatureImage src={leaderboardImage} alt="Global Leaderboard" />
        <strong>Global Leaderboard:</strong>
        <span>Challenge the world and climb to the top of the charts.</span>
      </ListItem>
      <ListItem>
        <FeatureImage src={coinsImage} alt="Coin Collection" />
        <strong>Coin Collection:</strong>
        <span>Gather coins while skillfully navigating through obstacles.</span>
      </ListItem>
    </List>
  </FeaturesSection>
);

const Devices = () => (
  <GameplaySection id="devices" aria-labelledby="devices-title">
    <Title id="devices-title">Play on the Apple Watch or iPhone!</Title>
    <Text>
      Dive into Splashy Sprint, the ultimate tap challenge, now on your Apple Watch and iPhone! Whether you're on the go or relaxing at home, Splashy Sprint is designed to give you an amazing experience right on your wrist. Enjoy the same thrilling gameplay and seamless performance on your Apple Watch – a rarity in mobile gaming. Tap away and conquer the ocean depths wherever you are!
    </Text>
  </GameplaySection>
);

const About = () => (
  <GameplaySection id="about" aria-labelledby="about-title">
    <Title id="about-title">How was it made?</Title>
    <Text>
      Splashy Sprint was brought to life by @lucythemaker and @selexin in just 7 days! Inspired by the lack of great games on the Apple Watch, they set out on a challenge to create something amazing in just one week. Follow their journey and other exciting projects on their social media:
    </Text>
    <SocialLinks>
      <SocialLink href="https://www.youtube.com/@lucythemaker" target="_blank" rel="noopener noreferrer">
        <SocialImage src={lucyImage} alt="@lucythemaker" />
        <span>@lucythemaker</span>
      </SocialLink>
      <SocialLink href="https://www.youtube.com/@selexin" target="_blank" rel="noopener noreferrer">
        <SocialImage src={jacobImage} alt="@selexin" />
        <span>@selexin</span>
      </SocialLink>
    </SocialLinks>
  </GameplaySection>
);

const FAQ = () => (
  <FAQSection id="faq" aria-labelledby="faq-title">
    <Title id="faq-title">Frequently Asked Questions</Title>
    <div>
      <Text><strong>What is Splashy Sprint?</strong></Text>
      <Text>Splashy Sprint is an addictive tapper game where you guide an underwater creature through the ocean. Available on the Apple App Store for both iPhone and Apple Watch, it's a thrilling adventure you can enjoy anywhere!</Text>
    </div>
    <div>
      <Text><strong>How do you play Splashy Sprint?</strong></Text>
      <Text>It's simple – tap the screen to keep your underwater creature swimming and dodge those tricky obstacles. The further you go, the harder it gets. Think you have what it takes?</Text>
    </div>
    <div>
      <Text><strong>Is Splashy Sprint free?</strong></Text>
      <Text>Yes! Splashy Sprint is free to download. Enjoy the core game and unlock extra features with optional in-app purchases.</Text>
    </div>
    <div>
      <Text><strong>Can I play Splashy Sprint on my Apple Watch?</strong></Text>
      <Text>Absolutely! Splashy Sprint is designed for both iPhone and Apple Watch, giving you a unique and immersive gaming experience right on your wrist.</Text>
    </div>
    <div>
      <Text><strong>Who created Splashy Sprint?</strong></Text>
      <Text>Splashy Sprint was crafted by the creative duo LucyTheMaker and Jacob. Inspired by the lack of great games on the Apple Watch, they built this game in just 7 days!</Text>
    </div>
    <div>
      <Text><strong>What makes Splashy Sprint unique?</strong></Text>
      <Text>From challenging gameplay to stunning underwater graphics, Splashy Sprint offers seamless Apple Watch integration and a global leaderboard. It’s a game like no other!</Text>
    </div>
    <div>
      <Text><strong>How do I download Splashy Sprint?</strong></Text>
      <Text>Easy! Head to the Apple App Store, search for "Splashy Sprint," and tap download. Ready to dive in?</Text>
    </div>
  </FAQSection>
);

const Footer = ({isPrivacyPage}) => (
  <FooterSection aria-labelledby="footer-title">
    <Text id="footer-title">&copy; 2024 Selexin. All rights reserved.</Text>
    <div>
      { !isPrivacyPage ? (
        <>
          <NavLink to="home" smooth>Home</NavLink>
          <NavLink to="gameplay" smooth>Gameplay</NavLink>
          <NavLink to="features" smooth>Features</NavLink>
          <NavLink to="devices" smooth>Devices</NavLink>
          <NavLink to="about" smooth>About</NavLink>
          <NavLink to="faq" smooth>FAQ</NavLink>
          <RouterNavLink to="/privacy-policy">Privacy Policy</RouterNavLink>
        </>
      ) : (
        <>
          <RouterNavLink to="/">Home</RouterNavLink>
          <RouterNavLink to="/privacy-policy">Privacy Policy</RouterNavLink>
        </>
      )}
      
      
    </div>
  </FooterSection>
);

const Header = ({isPrivacyPage}) => (
  <HeaderContainer aria-label="Main navigation">
    <Nav>
    { !isPrivacyPage ? (
      <>
        <NavLink to="home" smooth>Home</NavLink>
        <NavLink to="gameplay" smooth>Gameplay</NavLink>
        <NavLink to="features" smooth>Features</NavLink>
        <NavLink to="devices" smooth>Devices</NavLink>
        <NavLink to="about" smooth>About</NavLink>
        <NavLink to="faq" smooth>FAQ</NavLink>
        <RouterNavLink to="/privacy">Privacy Policy</RouterNavLink>
      </>
    ) : (
      <>
        <RouterNavLink to="/">Home</RouterNavLink>
        <RouterNavLink to="/privacy">Privacy Policy</RouterNavLink>
      </>
    )}
    </Nav>
  </HeaderContainer>
);

function HomePage() {
  return (
    <>
      <GlobalStyle />
        <ScrollToTop />
        <Header />
        <AppContainer>
          <Home />
          <Gameplay />
          <Features />
          <Devices />
          <About />
          <FAQ />
          <Footer />
        </AppContainer>
    </>
  )
}

function PrivacyPolicyPage() {
  return (
    <>
      <GlobalStyle />
      <ScrollToTop />
      <Header isPrivacyPage={true} />
      <AppContainer>
        <PrivacyPolicy />
        <Footer isPrivacyPage={true} />
      </AppContainer>
    </>
  )
}

function App() {
  return (
      <Router>
        <Routes>
          <Route path="/" element={<HomePage />} />
          <Route path="/privacy" element={<PrivacyPolicyPage />} />
        </Routes>
      </Router>
  );
}

export default App;
