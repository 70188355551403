// PrivacyPolicy.js
import React from 'react';
import styled from 'styled-components';

const Section = styled.section`
  width: 100%;
  max-width: 1000px;
  padding: 80px 20px;
  text-align: left;
  position: relative;
  z-index: 1;
  background: transparent;
`;

const Title = styled.h2`
  margin-bottom: 20px;
`;

const Text = styled.p`
  font-size: 1.5rem;
  line-height: 1.6;
  margin-bottom: 20px;
`;

const PrivacyPolicy = () => (
  <Section id="privacy-policy">
    <Title>Privacy Policy</Title>
    <Text>
      This Privacy Policy explains how Splashy Sprint collects, uses, and discloses information about you when you use our mobile application and website.
    </Text>
    <Text>
      <strong>Information Collection:</strong> We collect information you provide directly to us, such as when you create an account, update your profile, or communicate with us. We may also collect information about your use of the app, including your high scores, preferences, and game progress.
    </Text>
    <Text>
      <strong>Information Use:</strong> We use the information we collect to provide, maintain, and improve our services, to communicate with you, and to personalize your experience. We may also use the information for research and analytics purposes.
    </Text>
    <Text>
      <strong>Information Sharing:</strong> We may share your information with third-party service providers who perform services on our behalf. We may also share your information to comply with legal obligations or to protect our rights.
    </Text>
    <Text>
      <strong>Security:</strong> We take reasonable measures to protect your information from unauthorized access, use, or disclosure. However, no security measures are perfect or impenetrable.
    </Text>
    <Text>
      <strong>Changes to this Policy:</strong> We may update this Privacy Policy from time to time. We will notify you of any changes by posting the new Privacy Policy on our website. Your continued use of our services after any changes constitutes your acceptance of the new policy.
    </Text>
    <Text>
      <strong>Contact Us:</strong> If you have any questions about this Privacy Policy, please contact us at support@splashysprint.com.
    </Text>
  </Section>
);

export default PrivacyPolicy;
